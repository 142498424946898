.button {
  width: max-content;
  min-width: 13rem;
  height: 5.4rem;
  font-family: var(--font-sans-serif);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: var(--spacing-sm);
  background-color: var(--color-primary);
  border-radius: 3px;
  color: white;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}