@import '../../styles/mixins/container';
@import '../../styles/mixins/media';
@import '../../styles/mixins/stack';
@import '../../styles/settings/media-queries';

.App__wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  padding-top: 7rem;

  @media (--tablet) {
    padding-top: 10rem;
  }
  
  @media (--laptop) {
    flex-direction: row;
    min-height: 100vh;
    padding-top: 0;

    & > * {
      flex: 1;
    }
  }
}

.App__form-wrapper {
  @mixin container
    var(--container-width-sm),
    var(--container-padding-md),
    var(--container-padding-lg);

  @mixin stack var(--spacing-lg);

  padding-top: var(--spacing-md);
  padding-bottom: 7rem;

  @media (--tablet) {
    padding-bottom: 10rem;
  }
  
  @media (--laptop) {
    @mixin container
      var(--container-width-md),
      var(--container-padding-md),
      var(--container-padding-lg);

    row-gap: var(--spacing-md);
    /* contain: layout;
    contain-intrinsic-size: 0 30rem; */
  }
}

.App__image-wrapper {
  @mixin media;

  @media (--mobile) {
    @mixin media-aspect-ratio 15, 8;
  }

  @media (--laptop) {
    @mixin media-full;

    z-index: -1;
  }
}

.App__summary-wrapper {
  @media (--laptop) {
    height: 100%;
    min-height: 100vh;
  }
}

.App__image-wrapper,
.App__summary-wrapper {
  @media (--laptop) {
    position: absolute;
    right: 0;
    width: 50vw;
  }
}

.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.5s cubic-bezier(0.62, 0.28, 0.23, 0.99);
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
  transform: translateY(-100%);

  @media (--laptop) {
    transform: translateX(100%);
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition:
    opacity 0.5s cubic-bezier(0.62, 0.28, 0.23, 0.99),
    transform 0.5s cubic-bezier(0.62, 0.28, 0.23, 0.99);
}
