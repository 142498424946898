@import '../../styles/settings/media-queries';

.FormRadioGroup__options {
  --grid-column-gap: var(--spacing-sm) !important;
  --grid-row-gap: var(--spacing-sm) !important;
  
  @media (--phone-lg-min) {
    --grid-columns: 2 !important;

    grid-column: span 2;
    
    &--yes-no {
      --grid-columns: 1 !important;
    }
  }
  
  @media (--laptop) {
    --grid-columns: 3 !important;

    grid-column: span 3;
    
    &--yes-no {
      --grid-columns: 1 !important;
  
      grid-column: span 2;
    }
  }
}