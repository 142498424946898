@import '../../styles/settings/media-queries';

.FormSummary {
  display: flex;
  justify-content: center;
  height: 100%;
  /* padding: 0 var(--spacing-md); */
  background: var(--color-secondary);
  border-bottom: 1px solid var(--color-royal-blue);
  border-top: 1px solid var(--color-royal-blue);
  color: var(--color-primary);

  @media (--laptop) {
    padding: var(--spacing-md);
    border: 0;
    border-left: 1px solid var(--color-royal-blue);
  }
}

.FormSummary__inner {
  width: 100%;
  max-width: 47rem;
  
  @media (--laptop) {
    padding-top: 10rem;
  }
}

.FormSummary__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7.8rem;
  padding: 0 var(--spacing-md);
  color: var(--color-primary);

  @media (--laptop) {
    align-items: flex-start;
    padding: 0;
  }
}

.FormSummary__header-icon {
  width: 1.8rem;
  height: auto;

  @media (--laptop) {
    display: none;
  }
}


.FormSummary__details {
  display: none;
  padding: 0 var(--spacing-md) var(--spacing);

  @media (--laptop) {
    display: block !important;
    padding: 0;
  }

  &--is-open {
    display: block;
  }
}

.FormSummary__details-list {
  display: grid;
  grid: auto-flow / 0.4fr 0.6fr;

  dt,
  dd {
    padding: var(--spacing-sm) 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-royal-blue);
    }
  }

  dt {
    color: var(--color-grey-darker);
  }

  dd {
    color: var(--color-primary);
  }
}

/* .FormSummary__details-item {
  display: flex;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: var(--font-sans-serif);
  padding: var(--spacing-sm) 0;
  color: var(--color-primary);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-royal-blue);
  }
}

.FormSummary__details-item-label {
  width: 40%;
  color: var(--color-grey-darker);
} */