@import '../../styles/settings/media-queries';

.FormHandler {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-lg);

  @media (--laptop) {
    padding-top: 10rem;
  }
}

.FormHandler__content {
  /* contain: size;
  contain-intrinsic-size: 0 30rem; */

  --grid-columns: 1 !important;
  --grid-column-gap: var(--spacing-md) !important;

  padding-bottom: var(--spacing-xl);

  @media (--laptop) {
    padding-bottom: 0;

    .App__wrapper--with-image & {
      --grid-columns: 2 !important;
    }
  }
}

.FormHandler__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md-sm);
  background: var(--color-white);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);

  /* @media (--laptop) {
    all: unset;
  } */
}