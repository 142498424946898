@font-face {
  font-family: Freight;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url("/fonts/freight-regular.woff2") format("woff2"),
    url("/fonts/freight-regular.woff") format("woff"),
    url("/fonts/freight-regular.ttf") format("ttf");
}

@font-face {
  font-family: Freight;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src:
    url("/fonts/freight-black.woff2") format("woff2"),
    url("/fonts/freight-black.woff") format("woff"),
    url("/fonts/freight-black.ttf") format("ttf");
}

@font-face {
  font-family: Replica;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url("/fonts/replica-regular.woff2") format("woff2"),
    url("/fonts/replica-regular.woff") format("woff"),
    url("/fonts/replica-regular.ttf") format("ttf");
}

@font-face {
  font-family: Northwell;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url("/fonts/Northwell.woff") format("woff"),
    url("/fonts/Northwell.ttf") format("ttf");
}

@font-face {
  font-family: NunitoSans;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/NunitoSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: NunitoSans;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/NunitoSans-Bold.woff2") format("woff2");
}
