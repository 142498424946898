@import '../mixins/field';
@import '../settings/media-queries';

.field {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sm);
  font-size: 1.2rem;
  font-family: var(--font-sans-serif);
  
  @media (--phone-lg-min) {
    max-width: 37rem;
  }
}

.field__input {
  @mixin field-container;
  @mixin field-input;
  @mixin field-focus;
}

.field__textarea {
  @mixin field-container;
  @mixin field-input;
  @mixin field-focus;
}

.field__label,
.field__legend {
  font-size: 1.2rem;
  font-family: var(--font-sans-serif);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.field__validation-message {
  color: var(--color-dark-red);
}