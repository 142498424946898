@import './media-queries';

html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 62.5%;
  background: var(--color-white);
  color: var(--c-text);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
}

body {
  height: 100%;
  font-family: var(--font-serif);
  font-size: 1.6rem;
  line-height: calc(20 / 16);
  font-weight: 400;
  letter-spacing: 0.45px;
}

#root {
  min-height: 100%;
}
