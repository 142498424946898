@import '../../styles/settings/media-queries';

.HistoryNavigation {
  overflow: hidden;
  border-radius: 3px;

  /* @media (--laptop) {
    position: fixed;
    bottom: var(--spacing-md);
    right: var(--spacing-2xl);
  } */
}

.HistoryNavigation__button {
  width: 4rem !important;
  min-width: unset !important;
  height: 4rem !important;
  border-radius: 0 !important;
  /* background: var(--color-blue);
  color: var(--color-white); */

  &:disabled {
    opacity: 1 !important;
    color: var(--color-royal-blue);
  }

  &--back {
    border-right: 1px solid var(--color-royal-blue);

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    width: 1.8rem;
    height: auto;
  }
}