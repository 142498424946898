/* ------------------------------------ *\
  #Resets
\* ------------------------------------ */

/**
 * A very simple reset that sits on top of Normalize.css.
 */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
b {
  font-weight: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr,
dialog,
input,
label,
select,
button,
textarea,
iframe,
object {
  padding: 0;
  margin: 0;
}

main {
  display: block;
}

/**
  * Minor form resets
  */

input,
label,
select,
button,
textarea {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  letter-spacing: inherit;
  border: 0;
  appearance: none;
  background: none;
  border-radius: 0;
  color: inherit;
  outline: 0;
  white-space: normal;
}

fieldset {
  border: 0;
}

button {
  letter-spacing: inherit;
  text-transform: inherit;
  background: transparent;
  cursor: pointer;
  user-select: none;
}

textarea {
  resize: vertical;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
  &::-ms-check {
    display: none;
  }
}

a,
select,
button,
input,
textarea,
label,
summary {
  touch-action: manipulation;
}

input {
  /* FF error border */
  box-shadow: none;
}

select {
  &::-ms-expand {
    display: none;
  }
}

dialog {
  border: 0;
  background-color: transparent;
}

/* ------------------------------------ *\
   Links
 \* ------------------------------------ */

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

/* ------------------------------------ *\
   Lists
 \* ------------------------------------ */

/**
  * Remove trailing margins from nested lists.
  */

ul,
ol {
  list-style: none;
}

li > {
  & ul,
  & ol {
    margin-bottom: 0;
  }
}

/* ------------------------------------ *\
   Media
 \* ------------------------------------ */

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  font-style: italic;

  &[src*=".svg"] {
    width: 100% \9;
  }

  a & {
    border: none;
  }
}

::-ms-reveal,
img[src*=".svg"] {
  width: 100%;
}

audio,
canvas,
iframe,
video,
svg {
  vertical-align: middle;
}

iframe,
object {
  width: 100%;
  border: 0;
  background-color: transparent;
  outline: none;
}

/**
  * SVG reset.
  */

svg {
  display: inline-block;
  width: inherit;
  height: inherit;
  fill: currentcolor;
}
