@import "../settings/media-queries.css";

.grid {
  --grid-column-gap: var(--spacing);
  --grid-row-gap: var(--spacing);
  --grid-columns: 12;

  @media (--mobile) {
    --grid-columns: 1;
  }

  display: grid;
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
}

.grid:not(.cols-auto) {
  grid: auto-flow / repeat(var(--grid-columns), 1fr);
}

.grid.cols-auto {
  grid-auto-flow: column;
}

.grid.cols-2 {
  --grid-columns: 2;
}

.grid.cols-3 {
  --grid-columns: 3;
}

.grid.cols-4 {
  --grid-columns: 4;
}

.grid.cols-5 {
  --grid-columns: 5;
}

.grid.cols-6 {
  --grid-columns: 6;
}

.grid__item {
  --grid-column-span: initial;
  --grid-row-span: initial;

  grid-column: var(--grid-column-span);
  grid-row: var(--grid-row-span);
}

.grid__item.span-2-cols {
  --grid-column-span: span 2;
}

.grid__item.span-3-cols {
  --grid-column-span: span 3;
}

.grid__item.span-4-cols {
  --grid-column-span: span 4;
}

.grid__item.span-5-cols {
  --grid-column-span: span 5;
}

.grid__item.span-6-cols {
  --grid-column-span: span 6;
}

.grid__item.span-2-rows {
  --grid-row-span: span 2;
}

.grid__item.span-3-rows {
  --grid-row-span: span 3;
}

.grid__item.span-4-rows {
  --grid-row-span: span 4;
}

.grid__item.span-5-rows {
  --grid-row-span: span 5;
}

.grid__item.span-6-rows {
  --grid-row-span: span 6;
}
