@import '../../styles/settings/media-queries';


.FormCheckboxGroup {
  --grid-column-gap: var(--spacing-sm) !important;
  --grid-row-gap: var(--spacing-sm) !important;

  
  @media (--phone-min) {
    &--with-images {
      --grid-columns: 2 !important;
    }
  }

  @media (--phone-lg-min) {
    &--with-images {
      --grid-columns: 3 !important;
    }
  }

  @media (--tablet) {
    --grid-columns: 2 !important;

    grid-column: span 2;

    &--with-images {
      --grid-columns: 4 !important;
    }
  }
  
  @media (--laptop) {
    --grid-columns: 3 !important;

    grid-column: span 3;

    &--with-images {
      --grid-columns: 6 !important;
    }
  }
}