@import '../../styles/mixins/stack';
@import '../../styles/settings/media-queries';

.FormStep {
  @mixin stack var(--spacing-lg);

  position: relative;
}

.FormStep__heading {
  @mixin stack var(--spacing-sm);
}

.FormStep__content-wrapper {
  /* width: var(--mobile-content-width);

  @media (--tablet) {
    width: var(--tablet-content-width);
  }

  @media (--desktop) {
    width: var(--desktop-content-width);
  } */
}

.FormStep__legend {
  position: relative;
  white-space: pre-line;
}

.FormStep__fields {
  display: grid;
  column-gap: var(--spacing-sm);
  row-gap: var(--spacing-md-sm);
  grid-template-rows: max-content;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  
  &:not(.FormStep__fields--contact) {
    
  }
  
  &--passengers {
    @media (--tablet) {
      grid-template-columns: repeat(auto-fill, calc((100% / 2) - var(--spacing-xs)));
    }
  }

  &--contact {
    row-gap: var(--spacing-md-sm) !important;
    
    @media (--tablet) {
      /* grid-column: span 1; */
      grid-template-columns: repeat(auto-fill, calc((100% / 3) - var(--spacing-xs)));
    }

    & > * {
      grid-column: span 2;


      @media (--laptop) {
        /* grid-column: span 1; */
        /* grid-template-columns: repeat(auto-fill, calc((100% / 3) - var(--spacing-xs))); */
      }
    }
    
  }

  @media (--tablet) {
    grid-template-columns: repeat(auto-fill, calc((100% / 2) - var(--spacing-xs)));
  }

  @media (--laptop) {
    row-gap: var(--spacing-lg);
    grid-template-columns: repeat(auto-fit, calc((100% / 3) - (var(--spacing-sm) / 3)));
  }
}

.FormStep__field {
  /* .FormStep__fields--passengers & {
    grid-column-end: 3;
  } */
}

.FormStep__content-offset {
  @media (max-width: 128rem) {
    padding-top: 33vh;
  }
}

/* .FormStep__FormStepNum {
  position: absolute;
  top: 10.1rem;
  left: -1.6rem;

  .is-borrow-a-boat &,
  .is-barqo & {
    color: var(--color-grey-darker);
  }
} */

.FormStep__instructions {
  max-width: 42rem;
  margin-top: 1rem;
  margin-bottom: var(--FormStep-instructions-margin-bottom);

  .is-borrow-a-boat &,
  .is-barqo & {
    color: var(--color-grey-darker);
  }
}
