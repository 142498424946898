@import '../../styles/settings/media-queries';
@import '../../styles/mixins/utils';

.WidgetDatePicker {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-lg);
  grid-column: span 2;
}

.WidgetDatePicker__fields-wrapper {
  position: relative;
}

.WidgetDatePicker__fields {
  @media (--tablet) {
    --grid-columns: 2 !important;
    --grid-column-gap: var(--spacing-sm) !important;
  }
}

.WidgetDatePicker__popup {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: max-content;
  padding-bottom: var(--spacing-2xl);

  @media (--tablet) {
    top: calc(100% + var(--spacing-md));
    margin-bottom: 0;
  }
}

.WidgetDatePicker__popup-calendar {
  width: 30.9rem;
  font-size: 1.2rem;
  padding: var(--spacing);
  background: var(--color-white);
  border: 0;
  border-radius: var(--border-radius);
  box-shadow: var(--popup-shadow);

  @media (--tablet) {
    width: 32.8rem;
  }
}

.WidgetDatePicker__popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing);
  border-top: 1px solid #33333319;
  color: var(--color-primary);

  & > * {
    @mixin focus-ring;

    border-radius: 3px;
    outline-offset: 2px;
  }
}
