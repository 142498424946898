@define-mixin field-container {
  border: 1px solid var(--color-primary);
  background-color: var(--color-secondary);
  border-radius: var(--border-radius);
}

@define-mixin field-input {
  width: 100%;
  min-height: 5.4rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  padding: 1.85rem var(--spacing);
  color: var(--color-primary);

  &::placeholder {
    color: var(--color-primary);
    opacity: 0.7;
  }
}

@define-mixin field-focus {
  &:focus {
    box-shadow: inset 0 0 0 1px var(--color-primary);
  }

  &:focus-visible {
    outline: 3px solid var(--color-primary-low-opacity);
  }
}