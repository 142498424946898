@import '../mixins/container';

.container {
  @mixin container
    var(--container-width-md),
    var(--container-padding-md),
    var(--container-padding-lg);
}

.container-sm {
  @mixin container
    var(--container-width-sm),
    var(--container-padding-md),
    var(--container-padding-lg);
}

.container-lg {
  @mixin container
    var(--container-width-lg),
    var(--container-padding-md),
    var(--container-padding-lg);
}

.container-full {
  @mixin container
    100%,
    var(--container-padding-md),
    var(--container-padding-lg);
}
