@import '../../styles/settings/media-queries.css';

@define-mixin column-span {
  @media (--tablet) {
    grid-column: span 2;
  }

  @media (--laptop) {
    grid-column: span 3;
  }
}

.WidgetBudget {
  @mixin column-span;

  display: grid;
  grid-template-columns: 1fr;
  /* grid-column: span 3; */
  row-gap: var(--spacing-md);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--spacing-lg);
  }
  
  @media (--laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
  /*
  grid-template-areas:
    "prices"
    "budget-slider"
    "currency-select"
    "small-print";

  @media (--tablet) {
    grid-template: 1fr 1fr auto / auto var(--select-width-tablet);
    grid-template-areas:
      "prices currency-select"
      "budget-slider budget-slider"
      "small-print small-print";
  }

  @media (--desktop) {
    grid-template-columns: auto var(--select-width-desktop);
  } */
}

.WidgetBudget__budget-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* grid-column: span 3; */
  justify-self: center;
  
  /* @media (--tablet) {
  } */
  
  @media (--tablet) {
    justify-self: initial;
  }
}

.WidgetBudget__budget {
  font-family: var(--font-sans-serif) !important;
}

.WidgetBudget__price-per-person {
  margin-top: 1rem;
}

.WidgetBudget__currency-select {
  /* grid-column: span 3; */
  grid-row-start: 3;

  @media (--tablet) {
    grid-column: span 1;
    grid-row-start: 1;
    grid-column-start: 2;
  }
  
  @media (--laptop) {
    grid-column-start: 3;
  }
}

.WidgetBudget__slider {
  @mixin column-span;

  display: flex;
  flex-direction: column;
  /* grid-column: span 3; */

  /* @media (--tablet) {
    grid-column: span 2;
  }

  @media (--laptop) {
    grid-column: span 3;
  } */
}

.WidgetBudget__range-scale {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.WidgetBudget__small-print {
  @mixin column-span;

  /* width: 100%; */
  /* grid-column: span 3; */

  /* @media (--tablet) {
    grid-column: span 2;
  }

  @media (--laptop) {
    grid-column: span 3;
  } */
}
