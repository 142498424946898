@define-mixin stack $val: 0 {
  display: flex;
  flex-direction: column;
  row-gap: $val;

  &.stack--rev {
    flex-direction: column-reverse;
  }
}

@define-mixin unstack {
  @mixin stack 0;

  flex-direction: row;
  row-gap: 0;
}
