@import '../../styles/settings/media-queries';

.FormFieldset {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sm);

  @media (--tablet) {
    grid-column: span 2;
  }

  @media (--laptop) {
    grid-column: span 3;
  }
}