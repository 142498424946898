.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.just-between {
  justify-content: space-between;
}

.just-around {
  justify-content: space-around;
}

.just-center {
  justify-content: center;
}

.just-end {
  justify-content: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}
