@import '../../styles/settings/media-queries';

.FormFieldSelect {
  color: var(--color-primary);
}

.FormFieldSelect__label {
  width: 100%;
}

.FormFieldSelect__select-wrapper {
  position: relative;
  /* width: var(--mobile-width);

  @media (--tablet) {
    width: var(--tablet-width);
  }

  @media (--desktop) {
    width: var(--desktop-width);
  } */
}

/* .FormFieldSelect__input {
  box-shadow: inset 0 0 0 1px var(--color-primary);
} */

.FormFieldSelect__input:focus-visible {
  outline: 3px solid var(--color-primary-low-opacity);
}

.FormFieldSelect__down-arrow {
  position: absolute;
  top: 2.6rem;
  right: 2rem;
  color: var(--color-primary);
}

.FormFieldSelect__icon {
  position: absolute;
  top: 50%;
  right: var(--spacing);
  width: 1rem;
  height: auto;
  transform: translateY(-50%);
  pointer-events: none;
}
