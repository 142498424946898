@import '../settings/media-queries';

@define-mixin container $size, $paddingMin, $paddingMax {
  --container-size: $size;
  --padding-min: $paddingMin;
  --padding-max: $paddingMax;

  width: 100%;
  max-width: $size;
  padding-right: $paddingMin;
  padding-left: $paddingMin;
  margin-right: auto;
  margin-left: auto;

  @media (--tablet) {
    padding-right: $paddingMax;
    padding-left: $paddingMax;
  }
}

@define-mixin flush $side {
  --margins-width: calc(100vw - var(--container-size));
  --single-margin-width: calc(var(--margins-width) / 2);

  margin-$(side): calc(-1 * var(--padding-min));

  @media (--tablet) {
    margin-$(side): calc(-1 * var(--padding-max));
  }

  @media (--desktop) {
    margin-$(side): calc(-1 * var(--single-margin-width)) !important;
  }
}
