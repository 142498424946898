@import '../../styles/settings/media-queries';
@import '../../styles/mixins/media';

.FormInputOption {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* max-width: 37rem; */
  width: 100%;
  min-height: 5.4rem;
  padding: var(--spacing-sm);
  background-color: var(--color-secondary);
  border-radius:var(--border-radius);
  color: var(--color-primary);
  counter-increment: option-letter;
  cursor: pointer;

  &--basic {
    flex-direction: row !important;
    align-items: center;
  }

  .FormRadioGroup__options--yes-no & {
    /* max-width: 35.7rem; */
  }

  .FormCheckboxGroup & {
    flex-direction: column-reverse;
  }

  .is-borrow-a-boat & label,
  .is-barqo & label {
    color: var(--color-grey-darker);
  }
}

.FormInputOption__input-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sm);
  width: 100%;
  
  .FormInputOption--basic & {
    justify-content: center;
  }

  .FormInputOption--charter-type & {
    row-gap: 2rem;
  }
}

.FormInputOption__input {
  position: absolute;
  appearance: none;

  &:checked {
    & + .FormInputOption__label {
      &::before {
        border: 1px solid var(--color-primary);
        background: var(--color-primary);
        color: white;
      }

      &::after {
        border: 2px solid var(--color-primary);
      }
    }
  }

  /* &:focus, */
  &:focus-visible {
    & + .FormInputOption__label::after {
      outline: 3px solid var(--color-primary-low-opacity);
    }
  }

  .FormInputOption--column-reverse {
    flex-direction: column-reverse;
    padding: 10px;
  }
}

.FormInputOption__label {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;

  &::before {
    content: counter(option-letter, upper-alpha);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: 700;
    border: 1px solid var(--color-primary);
    background-color: var(--color-white);
    border-radius:var(--border-radius);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    border-radius:var(--border-radius);
  }
}

.FormInputOption__tick {
  &:not(.FormInputOption--basic &) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    width: 5rem;
    height: 5rem;
    padding: 0.9rem 0.75rem;
    background: var(--color-blue);
    border-radius: 0 var(--border-radius) 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    color: var(--color-white);

  }

  svg {
    width: 1.45rem;
    height: 1.1rem;
  }
}

.FormInputOption__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  column-gap: var(--spacing);
  row-gap: var(--spacing-sm);
  width: 100%;
  height: 100%;
  font-family: var(--font-serif);
  pointer-events: none;

  .FormInputOption--charter-type & {
    flex-direction: row-reverse;
  }
}

.FormInputOption__description {
  flex: 1;
  width: 100%;
}

.FormInputOption__image {
  position: relative;
  width: 100%;

  .FormInputOption--charter-type & {
    width: 4.5rem;
    height: auto;
  }
}

.FormInputOption__image-inner {
  &:not(.FormInputOption--charter-type &) {
    @mixin media;
    @mixin media-aspect-ratio 2, 1;

    .FormCheckboxGroup & {
      @mixin media-aspect-ratio 1, 1;
    }

    @media (--laptop) {
      @mixin media-aspect-ratio 12, 5;
      
    }
  }
}

.FormInputOption__callout-tag {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--color-blue);
  color: var(--color-white) !important;
}

.FormInputOption__footer {
  margin-top: auto;
  text-transform: uppercase;
}