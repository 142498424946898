@import '../../styles/settings/media-queries';
@import '../../styles/mixins/utils';

/* .react-datepicker {
  width: 30.9rem;
  font-size: 1.2rem;
  padding: var(--spacing);
  border: 0;

  @media (--tablet) {
    width: 32.8rem;
  }
} */

.react-datepicker__month-container {
  float: unset;
}

.react-datepicker__header {
  padding: 0;
  text-align: left;
  background-color: white;
  border-bottom: none;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-xs);
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 100%;
  color: var(--color-grey-darker);
  margin: 0;

  &:not(.react-datepicker__day--disabled):hover {
    background-color: var(--color-primary) !important;
    color: var(--color-white);
  }
  
  &:hover,
  &:not(.react-datepicker__day--disabled):hover {
    border-radius: 100%;

    /* &:not(.react-datepicker__day--in-selecting-range) {
    } */
  }
}

.react-datepicker__current-month {
  font-family: var(--font-serif);
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.8rem;
  padding-bottom: var(--spacing-sm);
  color: var(--color-grey-darker);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--keyboard-selected {
  background: initial;
  outline: 3px solid var(--color-primary-low-opacity);
  color: var(--color-grey-darker);
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--color-secondary);
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: var(--color-secondary) !important;
  border-radius: 100%;
  color: var(--color-primary);
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  background-color: var(--color-primary) !important;
  border-radius: 100%;
  color: var(--color-white);
}

.react-datepicker__day--selected {
  font-family: var(--link);
  font-size: 1.2rem;
  font-weight: 400;
  background-color: var(--color-primary);
  border-radius: 100%;
  color: var(--color-white);
}

.react-datepicker__day-name {
  font-family: var(--font-sans-serif);
  font-size: 1.2rem;
  width: 3.1rem;
  text-transform: uppercase;
  margin: 0;
}

.react-datepicker__day--disabled {
  color: var(--color-grey-light);
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xs);
  padding: 0;
  margin: 0;
}

.react-datepicker__navigation {
  @mixin focus-ring;

  position: absolute;
  top: var(--spacing);
  left: initial;
  width: 3.8rem;
  height: 3.8rem;
  margin-right: var(--spacing-xs);
  border-radius: var(--border-radius);

  &--previous {
    right: 4rem;
  }
  
  &--next {
    right: 0;
  }

  &:hover,
  &:focus-visible {
    .react-datepicker__navigation-icon::before {
      border-color: var(--color-primary);
    }
  }
}

.react-datepicker__navigation-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: rgba(0, 0, 0, 0);
  
  &::before {
    width: 1.3rem;
    height: 1.3rem;
    border-width: 2px 2px 0 0;
  }

  &--previous::before {
    transform: translate(25%) rotate(225deg);
  }

  &--next::before {
    transform: translate(-25%) rotate(45deg);
  }
}

.react-datepicker__navigation-icon,
.react-datepicker__navigation-icon::before {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
}

.react-datepicker__day-names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.1rem;
  padding: 0 var(--spacing-xs);
  margin-bottom: 0;
}