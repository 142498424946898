@define-mixin media-wrapper {
  position: relative;
  display: block;
  max-width: 100%;
  height: 0;
  overflow: hidden;

  & > * {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}

@define-mixin media {
  @mixin media-wrapper;

  & > * {
    @mixin media-item;
  }
}

@define-mixin media-item {
  object-fit: cover;
  object-position: center center;
}

@define-mixin media-full {
  height: 100% !important;
  padding-bottom: 0 !important;
}

@define-mixin media-wrapper-aspect-ratio $width, $height {
  padding-bottom: calc(100% * ($height / $width));
}

@define-mixin media-item-aspect-ratio $width, $height {
  aspect-ratio: $width / $height;
}

@define-mixin media-aspect-ratio $width, $height {
  @mixin media-wrapper-aspect-ratio $width, $height;

  & > img,
  & > video {
    @mixin media-item-aspect-ratio $width, $height;
  }
}
