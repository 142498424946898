@import "./media-queries.css";

::selection {
  background: var(--color-primary);
  color: var(--color-white);
}

/* :root {
  --font-serif: "Freight", times, georgia, serif;
  --font-sans-serif: "Replica", helvetica, arial, sans-serif;
  --handwriting: "Northwell", helvetica, arial, sans-serif;
  --bab-sans-serif: "NunitoSans", sans-serif;

  &.is-borrow-a-boat {
    --font-serif: "NunitoSans", sans-serif;
    --sans-serif: "NunitoSans", sans-serif;
  }

  &.is-barqo {
    --font-serif: "Open Sans", sans-serif;
    --sans-serif: "Poppins", sans-serif;
  }
} */

@define-mixin baseFont $font, $line, $letterSpacing:0 {
  font-size: calc($font * 0.1rem);
  line-height: calc($line / $font);
  letter-spacing: calc($letterSpacing * 0.001em);

  /* @if ($letterSpacing != '') {
      letter-spacing: calc($letterSpacing * 0.001em);
  } */
}

.text-h1 {
  font-family: var(--font-serif);
  font-size: 3rem;
  line-height: 4.6rem;
  font-weight: 400;
  letter-spacing: 0.75px;

  @media (--tablet) {
    font-size: 3.6rem;
  }

  @media (--phone-md) {
    font-size: 3rem;
    line-height: 3.3rem;
  }
}

.text-h2 {
  @mixin baseFont 24, 30;

  font-family: var(--font-serif);

  @media (min-width: b(small)) {
    font-size: 3rem;
  }
}

.text-h3 {
  @mixin baseFont 12, 17, 150;

  font-family: var(--font-sans-serif);
  text-transform: uppercase;
  letter-spacing: 0.15rem;


  color: var(--color-grey-dark);
}

.text-h4 {
  @mixin baseFont 22, 28;

  font-family: var(--font-serif);
  font-weight: 800;
  padding-bottom: 0.5rem;
}

.text-h5 {
  @mixin baseFont 15, 24;
  
  font-family: var(--font-sans-serif);
}

.text-h6 {
  @mixin baseFont 17, 24;
  
  font-family: var(--font-serif);
}

.text-uppercase {
  text-transform: uppercase;
}

.font-bab {
  * {
    font-family: var(--bab-font-sans-serif) !important;
    letter-spacing: 0 !important;
  }
}

.small-link-uppercase {
  font-family: var(--link);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.small-link-text {
  font-family: var(--link);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.small-text {
  font-family: var(--text);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.45px;
}

.medium-text {
  font-family: var(--text);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.45px;
}

.color-primary {
  color: var(--color-primary);
}

.color-grey-darker {
  color: var(--color-grey-darker);
}
