@import '../../styles/mixins/field';

.WidgetPhoneInput {
  @mixin field-container;

  display: flex;
  align-items: center;
  column-gap: var(--spacing-sm);
  padding-left: var(--spacing);

  /* &::before {
    content: attr(data-dial-code);
    width: max-content;
    height: max-content;
  } */

  &:focus-within {
    box-shadow: inset 0 0 0 1px var(--color-primary);
  }
}

.WidgetPhoneInput__dial-code {
  display: flex;
}

.WidgetPhoneInput__dial-code-input {
  padding-right: var(--spacing-xs);
}

.WidgetPhoneInput__select-icon {
  width: 1rem;
  height: auto;
  color: var(--color-blue);
  margin-left: -1rem;
  pointer-events: none;
}

.WidgetPhoneInput__number-input {
  @mixin field-input;

  flex: 1;
  width: unset;
  padding-left: 0;
}