@import '../../styles/mixins/utils';

.WidgetComboBoxInput__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-primary);

  &:focus {
    border: 2px solid var(--color-primary);
  }

  &:focus-visible {
    @mixin focus-ring;
  }
}

.WidgetComboBoxInput__input-wrapper {
  position: relative;
}

.WidgetComboBoxInput__icon {
  position: absolute;
  top: 50%;
  right: var(--spacing);
  width: 1rem;
  height: auto;
  transform: translateY(-50%);
  pointer-events: none;
}