@import '../../styles/settings/media-queries';

.WidgetSlider {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing);
  width: 100%;
}

.WidgetSlider__slider {
  position: relative;
}

.WidgetSlider__input {
  width: 100%;
  height: 6.4rem;
  background: var(--color-secondary);
  background-image: linear-gradient(var(--color-primary), var(--color-primary));
  background-repeat: no-repeat;
  background-size: var(--budget-slider-percent) 100%;
  border-radius: 14px;

  @media (--laptop) {
    height: 8.4rem;
  }

  &:focus {
    outline: 3px solid var(--color-primary-low-opacity);
  }

  &::-webkit-slider-thumb {
    opacity: 0;
  }
}

.WidgetSlider__left-right-arrows {
  position: absolute;
  top: 50%;
  left: var(--budget-slider-percent);
  color: var(--color-primary);
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.WidgetSlider__range-markers {
  display: flex;
  justify-content: space-between;
}
